h2 {
  font-family: 'Inter UI', 'Roboto', sans-serif; /* Use modern font stack */
  margin-top: 10px;
  margin-left: 50px; /* Adjust margin for better alignment */
  font-weight: bold; /* Make h2 bold */
  color: #333; /* Dark grey text color */
}

h4 {
  font-family: 'Inter UI', 'Roboto', sans-serif; /* Use modern font stack */
  margin-top: 10px;
  margin-left: 50px; /* Adjust margin for better alignment */
  font-weight: lighter; /* Make h4 thinner */
  color: #333; /* Dark grey text color */
}
.dashboard {
  display: flex;
  flex-grow: 1; /* This will make sure the dashboard grows to fill the space left by the sidebar */
  background-color: #6f0618;

}

.content {
  width: 85%; /* Adjust the width as needed */
  box-sizing: border-box; /* Include padding and borders in the element's total width */
  background-color: #f0f0f0;

}