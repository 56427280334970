/* /body {
  margin: 0;
  padding: 0;
  background-color: #FAF7F1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

  
@font-face {
  font-family: 'Jokker';
  src: url('./Jokker-Bold.woff') format('woff'); /* Adjust the path as needed */
  /*font-weight: bold;

/*
.sign-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FAF7F1; /* Same as body background */
  /*border: 1px solid #ccc; /* Light grey border */
 /* border-radius: 10px;
  padding: 50px;
  text-align: center;
/*}
  
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
  
.logo, .logo-text {
  height: 50px; /* Adjust the size as needed */
/*}

.logo {
  margin-right: 10px;
}
  
.logo-text {
  font-family: 'Jokker', sans-serif;
  font-size: 30px; /* Adjust the font size to match logo height */
/*}
  
.welcome-text {
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  margin-bottom: 40px;
}
  
.gsi-material-button {
  background-color: #fff;
  border: 1px solid #4285f4;
  border-radius: 40px;
  font-size: 18px; /* Increase font size */
  /*color: #000;
  padding: 15px 50px; /* Increase padding */
 /*font-family: 'Arial';
  box-shadow: none; /* Remove the shadow */
 /* cursor: pointer;
  outline: none;
  transition: transform 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
  
.gsi-material-button:hover {
  transform: scale(1.1); /* Increase size on hover */
/*}

.gsi-material-button:active {
  background-color: #e0e0e0;
}

.gsi-material-button-icon img {
  width: 24px; /* Adjust as needed */
  /*margin-right: 15px;
}
  
  .footer {
    font-family: 'Arial', sans-serif;
    font-size: 12px;
    color: #888;
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
  }

  */

  .sign-in {
    background-color: #FAF7F1; /* This color will only apply to the SignIn component */
  }
  .sidebar {
    position: relative; /* Add this line */
    flex: 0 0 15%; /* This will prevent the sidebar from growing and set its base size to 15% */
    background-color: #ffffff; /* Example background color */
    /* Ensure the sidebar extends full height */
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ccc; /* Border between sidebar and content */
    padding: 0%;
  }
  
  @font-face {
    font-family: 'Jokker';
    src: url('./Jokker-Bold.woff') format('woff');
    font-weight: bold;
  }
  
  .sign-in {
    background-color: #FAF7F1;
    border: 2px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; /* Stack the logo and text vertically */
  }
  
  .logo, .logo-text {
    height: 50px;
    display: inline-block;
  }
  
  .logo {
    margin-right: 0px;
  }
  
  .logo-text {
    font-family: 'Jokker', sans-serif;
    font-size: 30px; /* Match the size of the logo */
    padding-top: 10px;
  }
  
  .welcome-text {
    font-family: 'Arial', sans-serif;
    font-size: 24px;
    margin: 20px 0;
  }
  
  .gsi-material-button {
    background-color: #FFFFFF;
    border: solid 1px;
    border-color: #848484;
    border-radius: 22px;
    color: #525252;
    font-size: 16px;
    padding: 10px 24px;
    font-family: 'Arial', sans-serif;
    margin: 40px 0;
    cursor: pointer;
    outline: none;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .gsi-material-button:hover {
    box-shadow: 0 0 10px #4285F4;
    transform: scale(1.05);
  }
  
  .gsi-material-button:active {
    background-color: #eeeeee;
  }
  
  .gsi-material-button-icon {
    width: 24px !important;; /* Adjust the size to fit your design */
    height: auto; /* Maintain aspect ratio */
    margin-right: 2px;
  }
  
  .footer {
    font-family: 'Arial', sans-serif;
    font-size: 12px;
    color: #888888;
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
  }
  
  
  .gsi-material-button-icon img {
    width: 20px;
    margin-right: 12px;
  }
  
  body, html, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden; /* Prevent horizontal overflow */
  }

  .main-content {
    flex-grow: 1;
    padding: 20px; /* Adjust padding as necessary */
    box-sizing: border-box; /* Include padding in the element's total width */
    overflow-x: hidden; /* Prevents content from overflowing horizontally */

  }

  .content {
    max-width: 100%; /* Prevents element from exceeding the parent width */
    box-sizing: border-box; /* Includes padding and border in the element's width */
    overflow-x: hidden; /* Prevents content from overflowing horizontally */

  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Ensures padding does not add to the width */
  }
  body {
    overflow-x: hidden;
  }
      
  /* new CSS */ 

  