/* Update CalendarSettings.css */

.content-wrapper {
  margin-top: 20px; /* Add margin-top */
  
}

.calendar-settings {
  font-family: Arial, sans-serif;
  max-width: 800px; /* Adjust the maximum width */
  width: 80%; /* Set a percentage width for responsiveness */
  margin: auto; /* Center horizontally */
  background-color: #f0f0f0;
  padding: 433px; /* Increase padding for more space */
  border-radius: 12px; /* Increase border radius for rounded corners */
  display: flex; /* Use flexbox for centering */
  flex-direction: column; /* Arrange children vertically */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  margin-top: 20px; /* Add margin-top */

  
}

.day-container {
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255); /* Changed to a white or very light grey for a cleaner look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  border-radius: 16px; /* Slightly larger radius for a modern feel */
  margin: 50px 50px; /* Consistent margin on all sides for better spacing */
  border: none; /* Removing the border for a flatter design */
  padding: 2em; /* More padding for better content breathing room */
  align-items: stretch; /* Ensures children stretch to fill container width */
  max-width: 500px;
  
}


.day {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px; /* Space between day rows */
}

.day-name {
  width: 150px; /* Adjust width to ensure alignment of columns */
  margin-right: 10px; /* Space between day name and time input */
}

.time-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px; /* Space between start time and finish time inputs */
}

/* Update CalendarSettings.css */

.time-dropdown {
  position: relative;
  width: 100%; /* Set dropdown width to match the input box */
}

.time-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 4px 0; /* Add margin */
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  top: calc(100% + 80px); /* Adjusted to include proper spacing */
}

.time-dropdown ul li {
  padding: 8px;
  cursor: pointer;
  font-size: 12px; /* Smaller font size */
}

.time-dropdown ul li:hover {
  background-color: #f0f0f0;
}

.time-dropdown input {
  margin-bottom: 8px; /* Add margin to the bottom of the input */
}