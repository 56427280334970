/* App.css */
.App {
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
}

.app-container {
  width: 100%;
  flex: 1; /* Takes up the remaining space after sidebar */
}

.menu-item:hover .icon {
  fill: #9f9f9f !important; /* Brighter color for icons on hover */
}

.sidebar {
  position: fixed;
  width: 15%;
  background-color: #1E1E1E !important;
  display: flex;
  flex-direction: column;
  height: 100vh;
  border-right: 1px solid #444 !important;
  overflow-y: auto;
  padding-top: 20px;
  z-index: 1000;
}

.welcome-container h1 {
  margin-top: 50px; /* Increase this value to lower the text */
  font-family: Jokker;
}

.main-content {
  flex: 1;
  overflow-x: hidden;
  width: calc(100% - 15%); /* Adjust this value based on the sidebar's width */
  box-sizing: border-box; /* Ensure padding is included */
  margin-left: 15%; /* Make space for the fixed sidebar */
}

.user-profile {
  display: flex;
  align-items: center;
  margin: 20px;
}

.profile-picture {
  width: 25px;
  height: 25px;
  border-radius: 20%;
  margin-right: 8px;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-size: 12px;
  font-weight: bold;
  color: rgb(255, 255, 255);
}

.user-email {
  font-size: 10px;
  color: rgb(255, 255, 255);
}

.sign-out-button {
  background: linear-gradient(to right, #ff7e5f, #feb47b);
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 8px;
  margin-top: auto;
  margin-bottom: 20px;
}

.sign-out-button:hover {
  background-color: #d32f2f;
}

.menu-items {
  margin-top: 20px;
  color: white;
  flex-grow: 1;
  overflow-y: auto;
}

.menu-item a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-decoration: none;
  padding: 8px 16px;
  color: white !important;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  fill: white;
}

.menu-text {
  font-size: 14px;
  font-family: 'Inter UI', 'Roboto', sans-serif;
  font-weight: none;
  color: rgb(255, 255, 255);
  margin-left: 1px;
  text-align: center;
  align-items: center;
  text-align: left;
}

.App.center-sign-in {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.dashboard {
  background-color: #2b0909;
  display: 100%;
  flex-direction: column;
  align-items: right;
}

.content {
  width: 100%;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.content {
  max-width: 100%;
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  height: 100%;
  background-color: #F9F7F1;
}

.menu-item a {
  display: flex;
  width: 100%;
  text-decoration: none;
}

.menu-item a:hover {
  background-color: #333;
  cursor: pointer;
  align-items: center;
  color: black;
}

.menu-link {
  display: flex;
  align-items: center;
  padding: 8px;
  color: inherit;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.menu-link:hover {
  background-color: #e0e0e0;
}

.menu-link.active {
  font-weight: bold;
}

.icon {
  margin-right: 8px;
  text-align: center;
}

.welcome-container {
  background-color: #f0f0f0;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  margin: 0 auto;
}

.welcome-subtitle {
  font-size: 1.5em;
  color: #555;
  margin-bottom: 1em;
}

.welcome-text, .features-list, .upcoming-list {
  font-size: 1em;
  line-height: 1.6;
  color: #666;
}

.features, .upcoming-features {
  background-color: #f9f9f9;
  border-left: 4px solid #007bff;
  padding: 15px;
  margin-top: 1em;
}

.features-title, .upcoming-title {
  color: #333;
  margin-bottom: 0.5em;
}

.features-list li, .upcoming-list li {
  margin-bottom: 0.5em;
}

.welcome-title {
  font-size: 2em;
  color: #333;
  margin-bottom: 0.5em;
}

.help-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 18px;
  right: 16px;
  cursor: pointer;
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 16px;
  color: #333;
}

.help-icon:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.6), 0 0 40px rgba(0, 0, 0, 0.3);
  background-color: #e0e0e0;
}

.help-menu {
  position: fixed;
  bottom: 60px;
  right: 20px;
}

.chat-box {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.help-menu {
  position: fixed;
  bottom: 70px;
  right: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 200px;
  overflow: hidden;
  z-index: 1000;
}

.help-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.help-menu li {
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s;
}

.help-menu li:last-child {
  border-bottom: none;
}

.help-menu li:hover {
  background-color: #f7f7f7;
}

#intercom-container .intercom-launcher-frame,
#intercom-container .intercom-launcher-button {
  background-color: #808080 !important;
  border-radius: 50% !important;
}

.intercom-launcher-icon {
  display: none !important;
}

#intercom-container .intercom-launcher-button {
  background-image: url('https://www.heyava.ai/logo.png') !important;
  background-size: cover !important;
  background-position: center !important;
}
